/* app font */
$font: 'Helvetica Neue', Helvetica, Arial, sans-serif;

/* colors */
$ink: #0B0B0E;
$ink-25: #363949;
$ink-50: #6C8393;
$ink-65: #98A8B3;
$ink-80: #C4CDD4;

$paper-90: #E2E6E9;
$paper-95: #F0F3F4;
$paper-98: #F9FAFB;
$paper-100: #FFFFFF;

$primary-text: #122D54;
$primary: #2C71D3;
$primary-65: #6C9CE0;
$primary-80: #ABC6ED;

$prominent-text: #5C3A0A;
$prominent: #E6911A;
$prominent-65: #EDB25E;
$prominent-80: #F5D3A3;

$positive-text: #3D5214;
$positive: #99CC33;
$positive-65: #B7DB70;
$positive-80: #D4EBAD;

$destructive-text: #571B0F;
$destructive: #D94426;
$destructive-65: #E47C67;
$destructive-80: #F0B4A8;
$destructive-bg: #F7DAD4;
$destructive-note: #FF3B30;

$icon-mask: #858585;
$dashboard-card: #16171D;
$dashboard-button: #424557;

$dark: #333333;

$brand-orange: #FF6800;
$brand-orange-light: #FF9000;
$brand-purple: #AF52DE;
$brand-yellow: #FFD100;
$brand-green: #01B140;
$brand-blue: #00A2E0;
$brand-blue-dark: #245AA8;
$brand-red: #FF3B30;

/* font-sizes */
$tiny: 0.71rem;
$small: 0.85rem;
$middle: 1.28rem;
$large: 1.71rem;
$title: 2.29rem;
$huge: 3.14rem;

/* layout */
$public-sidebar-width: 440px;
$account-sidebar-width: 244px;
$account-sidebar-gap: 60px;
$container-width: 800px;
$side-gap: 20px;
