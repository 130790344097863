@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 400;
  src: local('HelveticaNeue'), local('Helvetica Neue'),
  url('/assets/fonts/HelveticaNeue-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 500;
  src: local('HelveticaNeue-Medium'), local('Helvetica Neue Medium'),
  url('/assets/fonts/HelveticaNeue-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Helvetica Neue';
  font-style: normal;
  font-weight: 600;
  src: local('HelveticaNeue-Bold'), local('Helvetica Neue Bold'),
  url('/assets/fonts/HelveticaNeue-Bold.woff') format('woff');
}
