@import 'vars';
@import 'fonts';

body, html {background: #fff; color: $ink; font-size: 14px; font-family: $font;
  min-height: 100%; margin: 0; padding: 0; -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &.sidebar-collapsed {
    .toast-container {width: calc(100% + 44px);}
  }
}

legend {all: unset;}

input, textarea {caret-color: $primary; margin: 0;}

input, select, textarea, button {outline: none; font-family: $font; font-size: 1rem;
  box-sizing: border-box; border: 0;
}

input, textarea, select {filter: none;}

/* text */
p {margin: 0 0 1rem 0; padding: 0;}
h1, h2, h3, h4 {margin: 0 0 1em 0; font-weight: bold; padding: 0;}
h1 {font-size: $large; line-height: 36px;}
h2 {font-size: $middle;}

/* links */
a {text-decoration: none; color: $primary; transition: all 0.6s; cursor: pointer;
  &:hover {transition: all 0.2s;}
}
.link {border-bottom: transparent 1px solid;
  &:hover {border-bottom-color: $primary;}
}

/* logo */
.logo {width: 32px; height: 32px;
  background: url(/assets/images/logo.svg) 50% 50% no-repeat; background-size: contain;
  &-text {width: 145px; height: 40px; min-height: 40px;
    background: url(/assets/images/logo-text.svg) 50% 50% no-repeat; background-size: contain;
  }
}

/* avatar */
.avatar {
  &-round {border-radius: 50%;
    .avatar-container {border-radius: 50%;
      &:after {border-radius: 50%;}
    }
  }
  &-content {font-family: $font !important; font-weight: bold !important; pointer-events: none;
    width: var(--avatar-size); height: var(--avatar-size); overflow: hidden;
  }
  &-hasinitials {
    .avatar-container {position: relative;
      &:after {content: ''; position: absolute; top: 0; left: 0;
        width: var(--avatar-size); height: var(--avatar-size);
      }
    }
  }
}

/* thumbnail */
.thumbnail {width: 100%; height: 100%; object-fit: cover;}


/* icons */
.icon {width: 16px; height: 16px; display: inline-block; vertical-align: text-top;
  background-image: url(/assets/images/icons.svg); background-position: var(--bg-position);
  font-size: 0;
  &-mask {mask-image: url(/assets/images/icons.svg); mask-position: var(--bg-position);
    background: $ink-65;
  }
  &-large {transform: scale(2);}
  &-medium {transform: scale(1.33);}
  &-small {transform: scale(0.875);}
  &-tiny {transform: scale(0.625);}
  &-cross {--bg-position: -128px 0;
    &-small {--bg-position: -112px 0;}
  }
  &-tick-small {--bg-position: -97px 0;}
  &-check-small {--bg-position: -94px -32px;}
  &-arrow-down {--bg-position: -176px 0;}
  &-arrow-up {--bg-position: -208px 0;}
  &-arrow-left {--bg-position: -224px 0;
    &-medium {--bg-position: -340px -75px;}
  }
  &-arrow-right {--bg-position: -192px 0;
    &-medium {--bg-position: -292px -76px;}
  }
  &-download {--bg-position: -192px -16px;}
  &-upload {--bg-position: -176px -16px;}
  &-document {--bg-position: -256px 0px;}
  &-video {--bg-position: -256px -16px;}
  &-file {--bg-position: -304px -16px;}
  &-building {--bg-position: -64px -48px;}
  &-brush {--bg-position: -128px -48px;}
  &-tasks {--bg-position: -96px -48px;}
  &-users {--bg-position: -160px -48px;}
  &-eye {--bg-position: -160px -16px;}
  &-eye-none {--bg-position: -160px 0;}
  &-qr {--bg-position: -144px 0;}
  &-lock {--bg-position: -144px -16px;}
  &-close {--bg-position: -128px 0;}
  &-more {--bg-position: -224px -16px;
    &-tiny {width: 12px; height: 12px; --bg-position: -48px -32px;}
  }
  &-edit {--bg-position: -96px -16px;
    &-tiny {width: 12px; height: 12px; --bg-position: -128px -32px;}
  }
  &-trash {--bg-position: -80px -16px;}
  &-options {--bg-position: -48px 0;}
  &-search {--bg-position: -48px -16px;}
  &-error {--bg-position: -32px -16px;}
  &-warn {--bg-position: -240px 0;}
  &-na {--bg-position: -112px -16px;}
  &-default {--bg-position: -160px -32px}
  &-double-check {--bg-position: -176px -32px}
  &-photo {--bg-position: -128px -16px;}
  &-plus {--bg-position: 0 -32px; width: 12px; height: 12px;
    &-medium {--bg-position: -272px -16px;}
  }
  &-minus {--bg-position: -16px -32px; width: 12px; height: 12px;
    &-medium {--bg-position: -288px -16px;}
  }
  &-refresh {--bg-position: -320px -16px;}
  &-menu {--bg-position: -32px -32px;}
  &-calendar {--bg-position: -80px 0;}
  &-thumbnail {--bg-position: -16px -16px;}
  &-alert {--bg-position: -240px -16px;}
  &-business {--bg-position: -24px -72px;}
  &-status {width: 20px; height: 20px;
    &-under-review {--bg-position: -50px -74px;}
    &-contested {--bg-position: -74px -74px;}
    &-removed {--bg-position: -98px -74px;}
    &-in-progress {--bg-position: -122px -74px;}
  &-closed {--bg-position: -146px -74px;}
    &-under-inspection {--bg-position: -170px -74px;}
    &-requesting-approval {--bg-position: -194px -74px;}
    &-requested-approval-rejected {--bg-position: -242px -74px;}
    &-declined {--bg-position: -218px -74px;}
    &-accepted {--bg-position: -266px -50px;}
  }
  &-draw {--bg-position: -272px 0;}
  &-hand {--bg-position: -288px 0;}
  &-approve {--bg-position: -176px -32px;}
  &-add {--bg-position: -304px 0;}
  &-plan {--bg-position: -288px 45px; width: 24px; height: 24px;}
  &-settings {--bg-position: -224px -48px;}
  &-document-copy {--bg-position: -209px -32px;}
}

/* grid */
.container {width: 100%; padding: 0 $side-gap; box-sizing: border-box; margin: 0 auto;}
.row {display: flex; flex-basis: 0; flex-wrap: wrap; margin: 0 -10px;
  &-largegap {margin: 0 -40px;
    .col {padding: 0 40px;}
  }
  &-nogap {margin: 0;}
}
.col {flex: 0 0 100%; padding: 0 10px; box-sizing: border-box;
  &-25 {width: 25%; flex: 0 0 25%;}
  &-33 {width: 33.6%; flex: 0 0 33.3%;}
  &-50 {width: 50%; flex: 0 0 50%;}
  &-66 {width: 66.6%; flex: 0 0 66.6%;}
  &-75 {width: 75%; flex: 0 0 75%;}
}

/* box */
.box {position: relative; background: #fff; border-radius: 8px; padding: 16px 24px;
  box-sizing: border-box;
}

.status {display: inline-block; line-height: 20px;
  &-icon {margin-right: 5px; vertical-align: top;}
}

/* table */
.table {
  &-row {position: relative; display: flex; align-items: flex-start;}
  &-cell {padding: 10px 8px; box-sizing: border-box; line-height: 21px; word-break: break-word;}
  &-head {color: $ink-50; font-size: $small; position: sticky; top: 0;
    background-color: #fff; z-index: 1;
    .table-row {border-bottom: $ink-80 2px solid; margin-bottom: 2px;}
    .table-cell {padding: 4px 8px; font-size: $small; line-height: $middle; top: 0;
      background-color: #fff; position: sticky; z-index: 1;
      a {color: $ink-50;}
      .icon {vertical-align: bottom;}
    }
  }
  &-content {padding-bottom: 2px;
    .dropdown-trigger {padding: 4px; border-radius: 3px;
      .icon {background-color: #fff;}
    }
    .table-row {border-radius: 3px;
      .avatar {margin: -4px 0;}
      &:hover {background-color: $paper-95;
        .dropdown-trigger .icon {background-color: $icon-mask;}
      }
    }
  }
  &-container {display: flex; position: relative; padding-bottom: 12px;
    &.empty {overflow: hidden;}
    &.loading {min-height: 200px;}
  }
  &-hasitems .table-content {border-bottom: 1px solid $ink-80;}
  &-actions {
    a, .button {position: relative; z-index: 1;}
  }
  &-projectdetails {
    .table {
      &-header {color: $ink-50;
        .table-row {border-bottom: 2px solid $ink-80;}
        .table-cell {height: auto; padding-bottom: 4px;}
      }
      &-content {padding: 0;
        .table-row {border-radius: 0;
          &:hover {background-color: transparent;}
        }
        .table-cell {color: #000;
          &:hover {background-color: $paper-95;}
        }
      }
      &-cell {height: 30px; display: inline-flex; align-items: center; padding: 0 8px;
        font-size: $small; line-height: 18px;
        &.empty {pointer-events: none;}
      }
    }
  }
}

/* page */
.page {height: 100%; min-height: 100vh; padding: 32px 20px; box-sizing: border-box;
  position: relative;
  &-title {font-size: $title; font-weight: bold; line-height: 36px; margin: 0;
    &-name {text-align: center; font-size: $large; text-transform: capitalize;
      margin: 0.57rem 0; word-break: break-word; color: $ink;
    }
    &-description {margin-top: 8px; font-size: 1rem; font-weight: 400; line-height: 21px;}
  }
  &-nothing {text-align: center; height: 25vh; padding-top: 25vh;
    &-title {font-size: $large;}
    &-message {color: $ink-25;}
  }
  &-noitems {position: absolute; bottom: 0; left: 0; right: 0; top: 50%;
    transform: translateY(-50%); text-align: center;
    &-message {padding: 56px 0;}
    &-title {font-size: $title; color: $ink; line-height: $huge; margin-bottom: 26px;
      font-weight: bold;
    }
    &-description {color: $ink-25; line-height: 1.5rem;}
    .icon-docs {width: 167px; height: 103px; display: inline-block; vertical-align: top;
      background-image: url(/assets/images/docs.svg);
    }
  }
  &-header {padding: 0 0 2.29rem; display: flex; align-items: baseline;
    &-actions {align-self: center; margin-left: auto;}
    &-wrapper {z-index: 2;}
  }
  &-close {position: absolute; top: 32px; right: 40px;}
  &-buttons {flex: 1 1 auto; text-align: right;
    .button {margin-left: 8px;
      &:first-child {margin-left: 0;}
    }
  }
  &-actions {display: flex; margin-top: 1.71rem;}
  &-action {font-weight: 500; margin: auto 0;
    .icon {margin-right: 8px; background-color: $destructive;}
    &:hover {opacity: 0.85;}
    &-right {margin-left: auto;}
    &-caution {color: $destructive;
      &:focus {color: #AE361E;
        .icon {background-color: #DD573C;}
      }
    }
    &.disabled {pointer-events: none; color: $ink-80;
      .icon {background-color: $ink-80;}
    }
  }
}

/* section */
.section {margin-bottom: 3rem;
  &-title {color: $ink-50; margin-bottom: $small; display: flex; justify-content: space-between;
    align-items: center;}
  &-content {position: relative;}
}

/* button */
.button {padding: 8px 14px; background-color: $paper-98; border: 1px solid $ink-80;
  border-radius: 3px; color: $ink; font-size: 1rem; outline: none; box-sizing: border-box;
  font-weight: 500; line-height: 18px; text-align: center; transition: all 0.2s;
  cursor: pointer; display: inline-block; vertical-align: top; margin: 0;
  &:hover {background-color: #fcfcfc;}
  &:focus {background-color: $paper-95;}
  &:disabled {background-color: $paper-95; color: $ink-80; border-color: $ink-80;
    pointer-events: none;
  }
  &-primary {background-color: $primary; color: #fff; border-color: #325BA3;
    &:hover {background-color: #417FD7;}
    &:focus {background-color: #235AA9; border-color: #25457A;}
  }
  &-remove {background-color: $destructive; color: #fff; border-color: #A13F29;
    &:hover {background-color: #DD573C;}
    &:focus {background-color: #AE361E;}
  }
  &-accept {background-color: $positive; color: #fff;
    &:hover {background-color: $positive-65;}
    &:focus {background-color: darken($positive, 10%);}
  }
  &-primary, &-remove {
    &.button-loading::after {border-color: rgba(#fff, 0.2); border-top-color: #fff;}
  }
  &-large {height: 44px; padding: 12px 24px; line-height: 20px; font-size: $middle;}
  &-loading {pointer-events: none; position: relative; color: transparent;
    .icon {background: transparent;}
    &::after {position: absolute; width: 12px; height: 12px;
      border: 2px solid rgba($primary, 0.4); border-top-color: $primary;
      animation: spin 1s linear infinite; content: ''; top: 0; left: 0; bottom: 0;
      right: 0; margin: auto; border-radius: 50%; pointer-events: none;
    }
  }
  &-bulk {padding: 9px; line-height: 0;
    &.active {background-color: #89b945; border-color: #89b945;
      &:hover {background-color: $positive-65;}
      &:focus {background-color: darken($positive, 10%);}
      .icon {background-color: #fff;}
    }
  }
}

/* loading spinner */
@keyframes spin {
  0% {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

.loading-spinner {
  &-default {min-width: var(--size); height: var(--size); pointer-events: none; overflow: hidden;
    position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);
    &:before, &:after {content: ''; width: var(--size); height: var(--size); position: absolute;
      top: 50%; left: 50%; margin-left: calc(var(--size) / (-2));
      margin-top: calc(var(--size) / (-2)); opacity: 0; border: 3px solid #000;
      border-radius: 50%; box-sizing: border-box;
    }
    &:before {opacity: 0.4;}
    &:after {border-color: transparent; border-top-color: #000;
      animation: spin 1s infinite linear; opacity: 1;
    }
  }
  &-auth {position: relative; height: 24px; margin: 1.5rem 0;
    &::after {content: ''; position: absolute; width: 24px; height: 24px;
      border: rgba(#fff, 0.2) 3px solid; border-top-color: #fff;
      animation: spin 1s linear infinite; top: 0; left: 0; bottom: 0; right: 0;
      margin: auto; border-radius: 50%; pointer-events: none; box-sizing: border-box;
    }
  }
  &-material {margin: 1.5rem auto;
    circle {stroke: $primary;}
  }
}

/* checkbox */
.checkbox {position: relative; line-height: 21px; margin-bottom: 3px;
  label {display: inline-block; width: calc(100% - 24px); padding-left: 24px;
    cursor: pointer; color: $ink;
  }
  &-icon {width: 16px; height: 16px; position: absolute; top: 2px; left: 0;
    border: 1px solid $ink-80; display: inline-block; background-color: $paper-98;
    box-sizing: border-box; border-radius: 3px; transition: all 0.3s;
    &::before, &::after {content: ''; background-color: #fff; width: 9px;
      position: absolute; top: 0; left: 0; margin: 3px; opacity: 0; transition: all 0.3s;
    }
    &::before {height: 2px; margin: 3px 0;}
    &::after {mask-image: url(/assets/images/icons.svg); mask-position: -100px -4px; height: 8px;}
  }
  label:hover input:not(:checked) ~ .checkbox-icon {background-color: $ink-80;}
  &:hover .checkbox-icon {border-color: $icon-mask;}
  input {opacity: 0; width: 0; height: 0; position: absolute;
    &:checked ~ .checkbox-icon {
      background-color: $primary; border-color: $brand-blue-dark;
      &::after {opacity: 1;}
    }
  }
  &.partial-checked {
    input:checked ~ .checkbox-icon {
      &:before {opacity: 1;}
      &:after {opacity: 0;}
    }
  }
  &-small {
    .checkbox-icon {width: 12px; height: 12px;
      &:before, &:after {margin: 1px;}
    }
  }
  &-medium {
    .checkbox-icon{width: 14px; height: 14px;
      &::before, &::after {margin: 2px;}
    }
  }
}
.flag {position: relative;
  &:hover {
    .tooltip {opacity: 1;}
  }
  label {cursor: pointer;}
  input {display: none;
    &:checked ~ .icon {background: $positive !important;}
  }
  .icon {background: $ink-65;}
}

/* toggle checkbox */
.toggle-checkbox {position: relative; line-height: 16px; padding: 4px 0;
  label {display: inline-block; padding-left: 38px; cursor: pointer; color: $ink;
    &:hover .toggle-checkbox-icon {background-color: $ink-25;}
  }
  &-icon {width: 30px; height: 16px; position: absolute; top: 4px; left: 0; display: inline-block;
    background-color: $ink-50; box-sizing: border-box; border-radius: 8px; transition: all 0.3s;
    &::after {content: ''; background-color: #fff; width: 14px; height: 14px;
      position: absolute; top: 1px; left: 1px; transition: all 0.3s; border-radius: 50%;
    }
  }
  input {opacity: 0; width: 0; height: 0; position: absolute;
    &:checked ~ .toggle-checkbox-icon {background-color: $positive;
      &::after {left: 15px;}
    }
    &:disabled ~ .toggle-checkbox-icon {pointer-events: none; background-color: $ink-80;}
  }
}

/* tooltip */
.tooltip {width: fit-content; position: absolute; top: -6px; left: 50%;
  transform: translate(-50%, -100%); padding: 4px 8px; background-color: #000;
  border-radius: 3px; font-size: $tiny; color: #fff; white-space: nowrap;
  opacity: 0; pointer-events: none; z-index: 2; transition: all 0.3s;
  box-sizing: border-box;
  &::before {content: ''; width: 8px; height: 8px; position: absolute; bottom: 0;
    left: 50%; transform: translate(-50%, 50%) rotate(45deg);
    background-color: inherit;
  }
  &-warn {background-color: $prominent; width: max-content; max-width: 201px;
    white-space: normal;
  }
}
.mat-tooltip {
  position: relative; padding: 4px 8px; background-color: #000; font-size: $tiny;
  border-radius: 3px !important; margin: 6px !important; overflow: visible !important;
  &::before {content: ''; width: 8px; height: 8px; position: absolute; bottom: 0; left: 50%;
    transform: translate(-50%, 50%) rotate(45deg); background-color: #000;
  }
}

/* role */
.role {margin-bottom: $large; text-align: center;
  &-name {border: 1px solid $paper-95; border-radius: 3px; background: $paper-95;
    color: $ink-50; padding: 2px 8px; font-size: $small;
  }
}

/* dropdown */
.dropdown {position: relative; display: inline-block; z-index: 2; line-height: normal;
  &-content {background: #fff; position: absolute; width: 250px; top: 100%; left: 0;
    text-align: left; opacity: 0; pointer-events: none; padding: 4px 3px;
    border: 1px solid $ink-80; border-radius: 3px; box-sizing: border-box;
    transition: all 0.3s;
    &-scrollable {overflow: hidden;}
  }
  &-trigger {line-height: 18px;}
  &-open {z-index: 5;
    .dropdown-content {top: 100%; opacity: 1; z-index: 1;
      pointer-events: auto;
    }
  }
  &-right .dropdown-content {left: auto; right: 0;}
  &-item {display: block; max-width: 100%; padding: 8px; border-radius: 2px;
    box-sizing: border-box; color: $ink; white-space: nowrap; overflow: hidden;
    text-overflow: ellipsis;
    &:hover {background: $paper-95;}
    &:last-of-type {border-bottom: 0;}
    &-caution {color: $destructive;
      .icon {background-color: $destructive; transition: all 0.3s;}
      &:hover {background-color: $destructive; color: #fff;
        .icon {background-color: #fff;}
      }
    }
    &.disabled {pointer-events: none; position: relative;}
  }
  &-scroll {max-height: 226px; overflow: hidden; overflow-y: scroll; scrollbar-width: none;
    &::-webkit-scrollbar {display: none;}
  }
  &-reset {position: absolute; top: 10px; right: 26px;
    .icon {background-color: $ink-80;
      &:hover {background-color: $ink-50;}
    }
  }
  &.disabled {background-color: $paper-95; pointer-events: none;
    input {background-color: $paper-95;}
  }
}

/* custom select */
.custom-select, .custom-multiselect {width: 100%; box-sizing: border-box;
  .dropdown-trigger {border: 1px solid $ink-80; border-radius: 3px; min-height: 36px;
    padding: 8px 12px; width: 100%; display: inline-block; box-sizing: border-box;
    color: $ink; background-color: #fff;
    &-placeholder {color: $ink-65;}
    &-icon {position: absolute; top: 0; right: 0; margin: 10px; transition: transform 0.3s;}
    &:hover {border-color: $ink-65;}
  }
  .dropdown-content {width: 100%; top: 100%; border-top: none; border-radius: 0 0 3px 3px;}
  &.dropdown-open {
    .dropdown-trigger {border-radius: 3px 3px 0 0; box-shadow: 0 0 2px $primary;
      border-color: $primary !important; border-bottom-color: transparent;
      &:after {content: ''; position: absolute; height: 1px; left: 4px; right: 4px;
        bottom: 0; background-color: $paper-95;
      }
      &-canrotate .dropdown-trigger-icon {transform: rotateX(180deg);}
    }
    .dropdown-content {margin-top: 0; border-color: $primary; box-shadow: 0 0 2px $primary;}
    &:before {content: ''; position: absolute; width: calc(100% - 2px); height: 2px;
      margin: 0 1px; background-color: #fff; top: calc(100% - 2px); left: 0; z-index: 1;
    }
    &:after {content: ''; position: absolute; width: calc(100% - 8px); height: 1px;
      margin: 0 4px; background-color: $ink-80; top: calc(100% - 1px); left: 0; z-index: 1;
    }
  }
  .dropdown-item {height: 32px;
    &.not-found {text-align: center;
      &:hover {background-color: transparent;}
    }
  }
  &-error {position: relative;
    .dropdown-trigger {
      &, &:hover {border-color: $destructive;}
    }
  }
  &.disabled .dropdown {
    &-trigger {background-color: transparent;}
    input {background-color: $paper-95;}
  }
}

.custom-select {
  .dropdown-trigger {padding-right: 20px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;
    vertical-align: top;
  }
}

/* custom multiselect */
.custom-multiselect {
  &.dropdown-hastags {width: 100%;
    .dropdown-tags {width: 100%; flex: 2 1 auto;}
  }
  .dropdown-trigger {transition: none; display: flex;
    .dropdown-filter {flex: 1 1 auto; height: 34px; margin: -8px 16px -8px -12px;
      display: inline-block; vertical-align: top;
      input {width: 100%; padding: 8px 12px; border-radius: 4px; line-height: 18px;
        display: inline-block; box-sizing: border-box;
        &::placeholder,
        &::-webkit-input-placeholder {color: $ink-65;}
      }
    }
    &-selected {padding: 2px 40px 2px 4px;
      .dropdown-filter {margin: -2px 16px -2px -4px;}
    }
  }
  .dropdown-items {
    .dropdown-item {height: 28px; line-height: $small;
      &:hover {
        input:not(:checked) ~ .checkbox-icon {background-color: $ink-65;}
      }
      label {pointer-events: none; position: relative;}
      input:checked ~ .checkbox-icon {background-color: $primary;}
    }
    .checkbox {padding: 0; line-height: $small;
      &-icon {top: 0; left: 0; background-color: $ink-80; border-color: transparent; pointer-events: none;}
    }
  }
  .dropdown-tags {display: block; margin-bottom: -4px; font-size: $small;
    pointer-events: none; overflow: hidden;
    .dropdown-tag {position: relative; height: 16px; max-width: 100%; display: inline-block;
      padding: 8px 26px 5px 8px; margin-right: 3px; background-color: $paper-90; border-radius: 3px;
      white-space: nowrap; overflow: hidden; text-overflow: ellipsis; line-height: 1rem;
      &-action {position: absolute; top: 0; right: 0; margin: 7px 4px; pointer-events: auto;}
      .icon {background-color: $ink-80;
        &:hover {background-color: $ink-50;}
      }
      &-disabled {padding-right: 8px;}
    }
    &-reset {position: absolute; top: 10px; right: 26px;
      .icon {background-color: $ink-80;
        &:hover {background-color: $ink-50;}
      }
    }
    &-count {position: absolute; right: 46px; top: 10px; font-size: $small; pointer-events: none;}
    &-hashidden:not(.dropdown-tags-hasfilter) {margin-right: 32px;}
  }
}

/* multiselect, users multiselect, package-activities multiselect */
.multiselect {
  &-item {height: 32px !important; padding: 10px 8px; display: flex; align-items: center;
    .icon {background-color: transparent; transition: 0.3s all; margin-right: 10px;}
    &:hover .icon {background-color: $ink-80;}
    &-selected .icon {background-color: $primary !important;}
  }
  &-name {flex: 1 1 auto; align-self: baseline;}
  .not-found a {pointer-events: all;}
}
.users-multiselect, .company-multiselect {
  .multiselect-tag {padding-left: 4px !important;
    .avatar {display: inline-block; vertical-align: top;}
  }
  .multiselect-item {height: 32px !important; padding: 10px 8px; display: flex;
    align-items: center;
    .avatar {margin-right: 8px;}
  }
}

/* forms */
.form {
  &-group {position: relative; margin-bottom: $small;
    &-labeled {
      .form-control {
        &-icon, &-action {
          &-right, &-left {top: 25px;}
        }
      }
    }
    .label-row {display: flex; color: $ink-50;
      &-spacer {flex: 1 1 auto;}
    }
    &-label {position: relative; display: block; margin-bottom: 8px; color: $ink-50;
      font-weight: 500; pointer-events: none;
      &.required:after {content: '*'; color: $destructive; position: relative;}
    }
    .form-radio {display: inline-block; margin-right: 16px;
      &:last-child {margin-right: 0;}
    }
    .textarea-wrapper {position: relative;
      textarea {height: 120px; resize: vertical; display: block;
        &::-webkit-resizer {display: none;}
      }
      &:before, &:after {content: ''; position: absolute; pointer-events: none;}
      &:before {bottom: 2px; right: 2px; height: 16px; width: 16px;
        background-color: transparent;
      }
      &:after {bottom: 0; right: 3px; width: 0; height: 0; border: 6px solid transparent;
        border-left-color: $ink-65; border-right: none; transform: rotate(45deg);
      }
    }
  }
  &-control {width: 100%; padding: 8px 12px; border: 1px solid $ink-80; border-radius: 3px;
    min-height: 36px; max-width: 100%; color: $ink; line-height: 18px; transition: all 0.2s;
    &:hover {border-color: $ink-65;}
    &:focus {border-color: $primary; box-shadow: 0 0 2px $primary;}
    &:disabled {background-color: $paper-95; pointer-events: none;}
    &::placeholder,
    &::-webkit-input-placeholder {color: $ink-65;}
    &-success, &-success:hover {border-color: $positive; box-shadow: none;}
    &-error {
      &, &:hover, &:focus {border-color: $destructive; box-shadow: none;}
    }
    &-iconic {
      &-left {padding-left: 32px;}
      &-right {padding-right: 32px;}
    }
    &-icon {
      &-left, &-right {position: absolute; top: 0; margin: 10px; pointer-events: none;}
      &-left {left: 0;}
      &-right {right: 0;}
    }
    &-action {
      &-left, &-right {position: absolute; top: 0; margin: 10px; cursor: pointer;
        .icon:hover {background-color: $ink-50;}
      }
      &-left {left: 0;}
      &-right {right: 0;}
    }
  }
  &-control::-ms-input-placeholder {color: $ink-65;}
  &-helper {width: fit-content; position: relative; padding: 4px 8px;
    background-color: $primary-80; border-radius: 3px; font-size: $tiny;
    color: #fff; margin: 6px auto 0;
    &::before {content: ''; width: 8px; height: 8px; position: absolute; top: 0;
      left: 50%; transform: translate(-50%, -50%) rotate(45deg);
      background-color: inherit;
    }
    &-success {color: $ink-50;}
    &-error {background-color: $destructive;}
  }
  &-control, &-group * {
    & ~ validation-errors .form-helper-error {pointer-events: none;
      position: absolute; top: 100%; left: 0; right: 0; z-index: 3;
      text-align: center;
    }
  }
  &-buttons {margin: 24px 0;}
  &-errors, &-messages {
    .form-error {background-color: $destructive-bg; color: $destructive-text;
      &::before {background-color: $destructive;}
      &-message {margin-bottom: 0.67rem;
        &:last-of-type {margin: 0;}
      }
    }
    .form-warn {background-color: $prominent-80; color: $prominent-text;
      &::before {background-color: $prominent;}
    }
    .form-success {background-color: $positive-80; color: $positive-text;
      &::before {background-color: $positive;}
    }
  }
  &-error,
  &-warn,
  &-success {position: relative; padding: 8px 16px; border-radius: 4px; color: #fff;
    line-height: 21px; overflow: hidden;
    &::before {content: ''; position: absolute; top: 0;left: 0; bottom: 0; width: 4px;}
    a {color: inherit !important;}
  }
  &-note {color: $destructive-note; font-size: $small;}
  &-action {cursor: pointer; background-color: $paper-100; color: $primary;}
  .row {margin: 0 -6px;}
  .col {padding: 0 6px;}
}

/* planview */
.planview {
  .plan-items {display: grid; grid-template-rows: auto auto; grid-row-gap: 25px; grid-column-gap: 26px;
    .card {border: 1px solid $paper-90; border-radius: 3px; min-width: 245px; color: $ink;
      &-description {padding: 17px 20px;}
      &-location {color: $ink-50; font-size: $small;}
      &-image {max-width:100%;}
      &-name {margin-bottom: 8px; word-break: break-word;}
    }
  }
  &-title {font-weight: 700; font-size: $middle}
  &-back {display: flex; align-items: center;
    .button {width: 36px; height: 36px; padding: 0;}
  }
}

/* modal */
.popup {position: absolute; width: 100%; height: 100%; top: 0; left: 0; z-index: 100;
  &-overlay {background: #000; opacity: 0.5; transition: opacity 0.3s;
    position: fixed; width: 100%; height: 100%; top: 0; left: 0; overflow: auto;
    &.cdk-overlay-backdrop {height: 100%;
      &.cdk-overlay-backdrop-showing {opacity: 0.5;}
    }
  }
  &-box {position: relative; padding: 24px 24px 10px; max-width: 400px; margin: 100px auto;
    text-align: center;
    &-confirmation {width: 294px;}
  }
  &-details,
  &-edit {position: relative; padding: 26px 32px 32px; background: #fff;
    box-sizing: border-box; border-radius: 3px;
    &-header {display: flex; align-items: baseline;
      .page-actions {margin: 0 0 0 auto;}
    }
    &-title {line-height: 27px; margin: 0;}
    &-subtitle {color: $ink-50;}
    .page-actions {justify-content: flex-end;
      .action:not(:last-child) {margin-right: 17px;}
    }
  }
  &-details {padding-bottom: 36px;
    &-header {margin-bottom: 3.79rem;}
    &.userdetails {width: 423px;}
    &.activitydetails {width: 600px;}
    .invisible {position: absolute;
      * {visibility: hidden; opacity: 0;}
    }
    .dropdown-actions.dropdown-open .dropdown {
      &-trigger {background-color: transparent !important;
        .icon {background-color: $ink-65 !important;}
      }
    }
  }
  &-wide {width: 600px;}
  &-extrawide {width: 865px;}
  &-subtitle {font-size: 1rem;}
  &-edit {
    &-header {margin-bottom: $small;}
    &.newsedit {width: 420px;}
    .form {
      &-buttons {display: flex; justify-content: flex-end; margin: 2.23rem 0 0;
        .button:not(:last-of-type) {margin-right: 8px;}
      }
    }
    &.dialogfile {
      .handover-file {margin-bottom: 24px;}
      .custom-scrollbar {max-height: 461px; overflow: hidden; overflow-y: auto; margin-right: -28px;
        margin-bottom: 24px;
        &-content {margin-right: 28px;}
      }
    }
    &.dialogdocuments{
      .form-group .popup-subtitle {margin-bottom: 1.14rem;}
    }
  }
  &-title {margin-bottom: $small; text-align: center; line-height: 28px; font-size: $middle;}
  &-message {line-height: 21px; margin-bottom: 16px;}
  &-buttons {border-top: 1px solid $paper-90; padding-top: 8px;}
  &-button {display: inline-block; vertical-align: top; width: 100%; text-align: center;
    padding: 8px 0; background-color: #fff; font-size: 1rem; outline: none;
    box-sizing: border-box; line-height: 18px; border: none; font-weight: 500;
    transition: all 0.2s; cursor: pointer;
    &-cancel {color: $primary;
      &:hover {color: $primary-text;}
    }
    &-confirm {color: $destructive;
      &:hover {color: $destructive-text;}
    }
    &.button-loading {color: transparent;}
    &.button-disabled {background-color: $paper-95; color: #fff;}
  }
  &-form {margin-bottom: 37px;
    &-title {margin-bottom: 16px; font-weight: bold;}
    &-action {font-weight: 500;
      .icon {margin-right: 5px;}
    }
  }
}
.activitydetails {padding-bottom: 10px;
  .popup-details-header {margin: 0;}
  .popup-details-header-wrapper {margin-bottom: $title;}
  .list-spinner .loading-spinner {position: relative; transform: translateX(-50%); margin: 20px 0;}
  .custom-scrollbar {max-height: 461px; overflow: hidden; overflow-y: auto; margin-right: -28px;
    &-content {margin-right: 28px;}
  }
  .toggle-tabs {margin-bottom: 0;}
  .page-nothing {margin: 100px 0;}
  .tasks {margin-top: 14px;
    &-label {display: flex; line-height: 21px; align-items: center; margin-bottom: 0.85rem;
      &:before {content: attr(data-tasks-number); min-width: 17px; height: 17px;
        display: inline-flex; align-items: center; justify-content: center;
        background-color: $primary; border-radius: 8px; font-size: 0.71rem; color: #fff;
        padding: 0 5px; box-sizing: border-box; margin-right: 8px;
      }
    }
  }
  .task {padding: 16px; border: 1px solid $paper-90; border-radius: 3px; line-height: 21px;
    &-flags {margin-top: 1rem; display: flex;
      .flag {font-size: $small; color: $ink-50;
        &:not(:last-child) {margin-right: 8px;}
        &-photo .icon {margin-right: 12px;}
      }
    }
    &.hidden {position: relative; border-color: rgba($paper-90, 0.6);
      &::before {content: ''; position: absolute; top: 0; right: 0; bottom: 0; left: 0;
        background-color: rgba(#fff, 0.6); z-index: 1;
      }
      .actions-hide {z-index: 1;}
    }
    .actions {margin-left: auto;
      &-hide {position: relative;
        &:hover .tooltip {visibility: visible; opacity: 1;}
      }
    }
  }
  account-package-activity-dialog-details-task {display: block;
    &:not(:last-of-type) {margin-bottom: 0.57rem;}
  }
  .subcontractors {
    &-name {flex: 1 1 auto; margin-left: 14px;}
    .custom-scrollbar {min-height: 220px;}
    .page-actions, .page-buttons {margin: 24px 0;}
    &-item {display: flex; align-items: center;
      padding: 12px 0; border-bottom: 1px solid $paper-90;
      .icon {background-color: $ink-80; transition: 0.3s all;
        &:hover {background-color: $ink-50;}
      }
    }
    .dropdown-items {max-height: 160px;}
    .form {margin-top: 12px;}
  }
  .files {width: 100%; margin: 16px 0;
    account-package-activities-files-item {
      .files-item {margin-bottom: 4px;}
      &:last-child .files-item {margin-bottom: 0;}
    }
    &-item {display: flex; position: relative; padding: 8px 12px; border-radius: 3px; overflow: hidden;
      border: 1px solid rgba($ink-50, 0.8); background-color: #fff; color: $ink; transition: 0.3s all;
      margin-bottom: 4px;
      &:hover {border-color: $ink-50; box-shadow: 0 0 2px 1px $ink-80;
        .files-item-actions {margin-right: 0;}
      }
      .icon {transition: 0.3s all;
        &:hover {background-color: $ink-50;}
      }
      &-name {flex: 1 1 auto; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;}
      &-actions {margin-right: -34px; transition: 0.2s all; white-space: nowrap; z-index: 1;}
      &-size {padding-right: 12px; color: $ink-65;}
    }
  }
}
.dialog {
  &-fullscreen {max-width: 100vw !important; position: absolute !important; top: 0;
    right: 0; left: 0; bottom: 0; overflow: hidden;
    .popup-slider {height: calc(100% - 105px); padding: 35px 35px 70px; color: #fff;
      background-color: rgba(#000, 0.7); display: flex; flex-direction: column;
      &-header {display: flex; align-items: baseline; justify-content: space-between;
        margin-bottom: 1.43rem;
        .icon-close {transform: scale(1.3); background-color: #fff;}
      }
      &-title {font-weight: normal;}
      &-counter {font-size: $middle;}
      &-images {position: relative; flex: 1; overflow: hidden; padding: 0 80px;
        img {object-fit: contain;}
        .popup-slider {
          &-prev, &-next {position: absolute; top: 50%; left: 0; width: 50px;
            height: 50px; border-radius: 50%; transform: translateY(-50%);
            background: #000; z-index: 2; transition: all 0.2s;
            &:hover {background: $primary;}
            .icon {position: absolute; top: 0; left: 0; bottom: 0; right: 0;
              margin: auto; background: #fff; transform: scale(3);
            }
          }
          &-next {left: auto; right: 0;}
        }
      }
      .swiper {
        &-container {height: 100%; overflow: hidden;}
        &-wrapper {position: relative; width: 100%; height: 100%; z-index: 1;
          display: flex; transition-property: transform; box-sizing: content-box;
        }
        &-slide {flex-shrink: 0; width: 100%; height: 100%; position: relative;
          transition-property: transform;
        }
      }
    }
  }
}

/* material dialog overrides */
.cdk-overlay-container {overflow: auto; pointer-events: auto;}
.cdk-overlay-backdrop {height: 100%; position: fixed;}
.cdk-global-overlay-wrapper {padding: 30px; box-sizing: border-box;}
.cdk-overlay-connected-position-bounding-box {pointer-events: none;}
.cdk-overlay-pane.mat-tooltip-panel {position: fixed;}
.mat-mdc-dialog-container {background: transparent !important; padding: 0 !important; border-radius: 0 !important;
  box-shadow: none !important; overflow: visible !important;}
.mat-mdc-dialog-surface {background: transparent !important; box-shadow: none !important; overflow: visible !important;}

/* toast notifications */
.toast-container {width: calc(100% + 244px); transition: all 0.2s; top: 0;
  .ngx-toastr {box-shadow: none; padding: 16px 18px 16px 48px; margin: 8px auto;
    font-family: $font; transition: all 0.6s; background-image: none;
    border-radius: 4px; line-height: 20px; width: unset; min-width: 448px;
    max-width: fit-content;
    &:hover {box-shadow: none; transition: all 0.2s; cursor: default;}
    &::before {width: 16px; height: 16px; position: absolute; top: 50%; left: 0;
      margin: 0 18px; transform: translateY(-50%); font-size: 0;
      mask-image: url(/assets/images/icons.svg);
    }
    .link {line-height: 16px;
      &:hover {border-bottom-color: rgba($ink, 0.25);}
    }
    .toast {display: flex; align-items: center; justify-content: space-between;
      &-message, &-actions {display: inline-block;}
      &-message {max-width: 600px;}
      &-actions {position: relative; float: right; margin-left: 52px;}
    }
    .countdown-progressbar {position: absolute; top: 0; right: 0; margin: -3px 40px;}
  }
  .ngx-toastr-full {border-radius: 0; margin: 0; width: 100%; min-width: 100%;
    padding: 18px 42px 18px 70px;
    &:before {left: 16px; margin: 21px;}
  }
  .toast-success {background-color: $positive-80; color: $positive-text;
    &::before {content: ''; background: $positive; mask-position: -64px -16px;}
    .link {color: $positive-text;}
    .countdown-progressbar {
      text {fill: $positive-text;}
      circle {stroke: $positive;}
    }
  }
  .toast-error {background-color: $destructive-80; color: $destructive-text;
    &::before {content: ''; background: $destructive; mask-position: -32px -16px;}
    .link {color: $destructive-text;}
    .countdown-progressbar {
      text {fill: $destructive-text;}
      circle {stroke: $destructive;}
    }
  }
  .toast-warning {background-color: $prominent-80; color: $prominent-text;
    &::before {content: ''; background: $prominent; mask-position: -240px -16px;}
    .link {color: $prominent-text;}
    .countdown-progressbar {
      text {fill: $prominent-text;}
      circle {stroke: $prominent;}
    }
  }
}

/* auth */
.auth {
  &-intro {line-height: 21px; margin: $large 0;}
  &-loading {display: flex; align-items: center; justify-content: center;
    position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 10;
    background-color: rgba(11, 13, 14, 0.8); pointer-events: none;
  }
}

/* custom scrollbar */
.custom-scrollbar {
  &::-webkit-scrollbar {width: 4px; height: 4px; background-color: rgba(11, 13, 14, 0.1);
    border-radius: 2px;
  }
  &::-webkit-scrollbar-thumb {border-radius: 2px; background-color: $ink-65;}
}

/* drag & drop */
.draggable-item {
  position: relative; margin-bottom: 4px;
  &:hover &-remove {opacity: 1;}
  &-action {position: absolute; top: 10px; width: 16px; height: 16px;
    background-color: $paper-98; border: 1px solid $paper-90; border-radius: 50%;
    opacity: 0; transition: all 0.3s;
    .icon {margin: 2px;}
    &:hover {opacity: 1;}
  }
  &-handler {left: -24px; cursor: move;}
  &-add {opacity: 1;}
  &-remove, &-add {right: -24px;}
}
.cdk-drag-placeholder {background-color: $paper-90; border-radius: 3px;
  * {opacity: 0;}
  .draggable-item-action {opacity: 0; pointer-events: none;}
}
.cdk-drag-animating {transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);}
.cdk-drop-list-dragging {
  *:not(.cdk-drag-placeholder) {transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);}
  .draggable-item-action {opacity: 0 !important; pointer-events: none !important;}
}
.cdk-drag-preview .draggable-item-handler {opacity: 1;}

/* packages, package-activities drag & drop */
.packages .draggable-item input,
.cdk-drag-preview.packages-item {
  packages-item {position: relative;
    .form-control {padding-right: 30px;}
    .warns {height: 20px; position: absolute; top: 0; right: 12px; display: flex;
      align-items: center;
      .warn .icon-mask {background-color: $prominent;}
    }
  }
  .form-control {
    width: 100%; height: 36px; padding: 8px 12px; color: $ink;
    box-sizing: border-box; transition: all 0.3s;
    &:not(.form-control-error) {border-radius: 3px; border: 1px solid $paper-90; box-shadow: 0 1px 4px 1px $paper-95;}
    &:not(.form-control-error):not(:focus):hover {border-color: $ink-65;}
    &:focus {border-color: $primary;}
  }
}
.cdk-drag-preview.package-activities-task {
  .task {position: relative; border: 1px solid $paper-90; box-shadow: 0 1px 4px 1px $paper-95;
    border-radius: 3px; background-color: #fff;
    &-flags {height: 20px; position: absolute; top: 9px; right: 12px; display: flex;
      align-items: center;
      .flag {
        &:not(:last-child) {margin-right: 8px;}
        &:hover .icon {background: $ink-65;}
        .icon {background: $ink-80;}
      }
    }
    textarea {display: block; padding-right: 56px; overflow: hidden;
      resize: none; line-height: 21px; border: none; box-shadow: none;
      &::-webkit-resizer {display: none;}
    }
    input {width: calc(100% - 6px); height: 36px; padding: 8px 9px; color: $ink;
      border-top: 1px solid $paper-90; box-sizing: border-box; margin: 0 3px;
      display: none; line-height: 21px; background-color: transparent;
      &::placeholder,
      &::-webkit-input-placeholder {color: $ink-65;}
      &.visible {display: initial;}
    }
  }
}

/* image uploader */
.imageuploader {position: relative;
  &-area {height: 36px !important; border: 1px dashed $primary-80 !important;
    border-radius: 4px !important; color: $primary !important; font-size: 1rem !important;
    position: relative; margin-bottom: 0.8rem; padding: 8px 12px; box-sizing: border-box;
    overflow: hidden; transition: all 0.3s; cursor: pointer;
    .icon {position: absolute; background-color: $primary;}
    &:hover {border-color: $primary !important;}
    &.ngx-dz-hovered {border-color: $primary !important; border-style: solid !important;}
  }
  &-progress-bar {position: absolute; top: 0; left: 0; height: 100%; background-color: $paper-95;}
  &-name {position: relative; display: inline-block; line-height: 18px; margin-left: 28px;}
  &-image {width: 16px; height: 16px; background-color: #000; vertical-align: middle;}
  &-remove, &-thumbnail {position: absolute; top: 0; left: 0;}
  &-remove {height: 16px; margin: 10px 12px; z-index: 1; transition: all 0.3s;
    .icon {background-color: $primary;}
  }
  &-thumbnail {margin: 9px 12px;
    &-multiple {margin: 0; top: -15px; left: -15px;}
  }
  &-info {height: 16px; color: $ink-50; font-size: $small; line-height: 1rem;}
  .loading-spinner {transform: none;
    &:before, &:after {border-width: 2px;}
    &:before {border-color: $primary;}
    &:after {border-top-color: $primary;}
  }
}

/* material datepicker */
.form-group-datepicker {
  .mat-mdc-form-field {max-width: 100%; font-family: inherit; line-height: inherit; font-size: inherit;
    letter-spacing: inherit; font-weight: inherit;
    &-label {top: 0; padding: 10px 28px 10px 12px; box-sizing: border-box; color: $ink-65; line-height: 1rem;
      &-wrapper {top: 0; padding: 0;}
    }
    &-underline {display: none !important;}
    &-wrapper {padding-bottom: 0 !important;}
    &-infix {padding: 0 !important; font-family: $font; min-height: auto;
      .icon {position: absolute; top: 0; right: 0; margin: 9px; pointer-events: none;}
      .mdc-text-field__input {border: 1px solid $ink-80;}
    }
    .mat-mdc-text-field-wrapper {padding: 0 !important; border-radius: 3px; cursor: pointer;
      &.mdc-text-field--focused {box-shadow: 0 0 2px $primary;}
    }
    .mdc-notched-outline, .mdc-line-ripple {display: none;}
    .mat-mdc-floating-label {top: unset !important; padding: 10px 28px 10px 12px; box-sizing: border-box; color: $ink-65 !important;
      font: inherit; letter-spacing: inherit; transform: none !important;}
  }
  .form-control {border: 1px solid $ink-80; border-radius: 3px; box-sizing: border-box;
    padding: 8px 28px 8px 12px; background-color: #fff; line-height: 18px; cursor: pointer !important; vertical-align: middle;
    &:hover {border-color: $ink-65;}
    &:focus {border-color: $primary;}
    &:disabled {background-color: $paper-95; pointer-events: none;}
    &-error {
      &, &:hover, &:focus {border-color: $destructive;}
    }
  }
  &.opened {
    .form-control {border-color: $primary !important; box-shadow: 0 0 2px $primary;}
    .mdc-text-field {box-shadow: 0 0 2px $primary;}
    &-below {
      .form-control {border-bottom-color: transparent !important; border-radius: 3px 3px 0 0;}
    }
    &-above {
      .form-control {border-top-color: transparent !important; border-radius: 0 0 3px 3px;}
    }
  }
}
.mat-datepicker-content {position: relative; border: 1px solid $primary; overflow: hidden;
  border-radius: 3px !important; box-shadow: 0 0 2px $primary !important; border-top: 1px solid $primary;
  .mat-calendar {width: 290px !important; height: auto !important; font-family: $font !important;
    &-controls {margin: 0 !important; padding: 0 4px; height: 46px; align-items: center;
      border-bottom: 1px solid $paper-95;
      .mdc-button {letter-spacing: inherit;
        .mdc-button__label {position: initial;}
      }
      .mat-button:hover .mat-button-focus-overlay {opacity: 0 !important;}
      .mat-calendar {
        &-previous-button, &-next-button {width: 16px; height: 16px; margin: 4px; background-color: #fff;
          &:after {border: unset; transform: unset; background-color: $ink-65; width: 16px; height: 16px;
            mask-image: url(/assets/images/icons.svg); margin: 0;
          }
          &:hover:after {background-color: $ink-50;}
        }
        &-previous-button {order: 1;
          &:after {mask-position: -32px 0;}
        }
        &-period-button {order: 2; flex: 1 1 auto; border-radius: 0; text-transform: lowercase; font-family: $font;
          margin: 0 12px; background-color: $paper-95; height: 32px; line-height: 32px; border-radius: 4px;
          .mdc-button__label span {display: inline-block; position: absolute; width: 100%; left: 0; top: 0;
            &::first-letter {text-transform: uppercase;}
            &:after {content: ''; position: absolute; background-color: $ink-50; top: 0; right: 0; margin: 8px 12px;
              width: 16px; height: 16px; mask-image: url(/assets/images/icons.svg); mask-position: -176px 0;}
          }
        }
        &-next-button {order: 3;
          &:after {mask-position: -16px 0;}
        }
        &-spacer {display: none;}
      }
    }
    &-header {position: relative; padding: 2px 4px; border-radius: 3px; background-color: #fff;}
    &-table {width: 90%; margin: auto;
      &-header {
        th {padding: 14px 0 10px; color: $ink-50; font-size: $tiny;}
        &-divider {display: none;
          &::after {background: transparent !important;}
        }
      }
    }
    &-content {padding: 2px 8px;}
    &-body {font-size: 1rem;
      &-label {padding: 0 !important; font-size: 0;}
      &-cell {
        &-content {border-radius: 3px !important; border: none; width: 80%; height: 80%;}
        &:hover .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
            border-color: transparent !important; background-color: $paper-95 !important; color: $primary;
        }
        &.mat-calendar-body-disabled {
          .mat-calendar-body-cell-content {color: $ink-80;}
          &:hover .mat-calendar-body-cell-content {background-color: transparent !important; color: $ink-80;}
        }
      }
      &-selected {border-color: transparent !important; background-color: $primary !important; color: #fff;}
      &-today {box-shadow: none;}
    }
    &-arrow {display: none;
      &:after {content: ''; position: absolute; background-color: $ink-50; top: 0; left: 0;
        width: 16px; height: 16px; mask-image: url(/assets/images/icons.svg); mask-position: -176px 0;}
      &.mat-calendar-invert {transform: unset;
        &:after {transform: rotate(180deg);}
      }
    }
    &.calendar-inverted .mat-calendar-period-button .mdc-button__label span:after {transform: rotate(180deg);}
  }
  .actions {
    .mat-icon {color: $primary; width: unset; height: unset;
      &::first-letter {text-transform: uppercase;}
    }
  }
  .mat-datepicker-actions {gap: 0.5rem;}
}
.cdk-program-focused .mat-calendar-body-active>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
  background-color: #fff;
}

/* info */
.info {
  &-header {display: flex; padding-bottom: 0.6rem; border-bottom: 1px solid $ink-80;
    margin-bottom: 1.4rem;
  }
  &-label {font-size: $small; text-transform: uppercase; color: $ink-50; letter-spacing: 1px;}
  &-actions {display: inline-flex; align-items: center; margin-left: auto;
    .info-action {display: inline-flex; align-items: center;
      &:not(:last-child) {margin-right: 17px;}
      &:hover {
        .info-action-label {color: $ink-25;}
        .icon-mask {background-color: $ink-25;}
      }
      &-label {margin-left: 8px; font-size: $small; color: $ink-65;}
      &-edit {vertical-align: middle;}
    }
  }
  &-empty {margin: 1.71rem 0 1.14rem; color: $ink-65;}
  &-action:hover {color: $primary-65;}
  .property {display: flex; align-items: flex-end; margin-bottom: 1.71rem;
    word-break: break-word;
    &:last-child {margin-bottom: 0;}
    &-first {border-top: 1px solid $ink-80; padding-top: 1.93rem;}
    &-name {width: 24%; flex: 0 0 24%; overflow: hidden; position: relative;
      margin-right: 5px; line-height: 20px; color: $ink-65; align-self: baseline;
    }
    &-value {width: 76%; flex: 0 0 76%; align-self: flex-end; line-height: 20px;
      a {color: $ink; outline: none;}
      a:hover {color: $ink-25;}
    }
  }
}

/* project */
.project {
  &-avatar {border-radius: 3px; box-shadow: inset 0 0 0 1px rgba(11, 13, 14, 0.05);
    overflow: hidden; background-color: $paper-95;
    &-empty {position: relative; box-shadow: none;
      &::before {content: attr(data-firstletter); position: absolute; top: 50%;
        left: 50%; transform: translate(-50%, -50%); color: $ink-80; text-transform: uppercase;
        font-weight: bold;
      }
    }
  }
  &-name {font-size: $middle; font-weight: bold; line-height: 27px; margin-bottom: 0.29rem; margin-right: 1.3rem;}
  &-status {width: fit-content; padding: 1px 4px; border-radius: 3px; color: #fff;
    font-size: 0.71rem; line-height: 15px; box-sizing: border-box; white-space: nowrap;
    &.underconstruction {background-color: $prominent;}
    &.tendering {background-color: $paper-95; color: $ink-50;}
    &.partiallycompleted {background-color: $primary;}
    &.completed {background-color: $positive;}
    &.archived {background-color: #fff; color: $ink-50; border: 1px solid $ink-50;}
  }
  &-number,
  &-completiondate {font-size: $small; line-height: 18px; color: $ink-50;}
}

/* qualityissuereworkdetails */
.qualityissuereworkdetails {line-height: 21px;
  &-action-edit {position: absolute; top: 0; right: 0; width: 36px; height: 36px;
    .icon {position: absolute; top: 0; left: 0; right: 0; bottom: 0; margin: auto;}
    &:hover .icon-mask {background-color: $ink-50;}
  }
  .header {position: relative; margin-bottom: 32px;
    &-back {font-weight: 500; margin-bottom: 36px;}
  }
  .page-title {padding-right: 36px;}
  .specification {position: sticky; position: -webkit-sticky; top: 0;
    &-header {font-size: $small; margin-bottom: 0; color: $ink-50;}
    &-item {margin-bottom: 24px;}
  }
  projects-details-quality-issues-details-specification,
  projects-details-rework-report-details-specification {display: contents;}
  .details {
    &-qualityissue {max-width: 200px; padding: 8px; border-radius: 3px;
      background-color: $paper-98;
    }
  }
}


.preview {width: 187.5px; height: 187.5px; border: 1px solid $ink-80; object-fit: contain;
  &-wrapper {margin-bottom: 1rem;}
}

/* tabs */
.toggle-tabs {display: flex; border-bottom: $ink-80 1px solid; font-weight: 500; position: relative; margin-bottom: 14px;
  .toggle-tab {position: relative; padding: 12px 0; margin: 0 8px; color: $ink-65;
    &:hover {color: $primary;}
    &:first-child {margin-left: 0;}
    &:last-child {margin-right: 0;}
    &.active {color: $primary;
      &:after {content: ''; position: absolute; bottom: -1px; left: 0; width: 100%; height: 3px;
        background-color: $primary;
      }
    }
    &.disabled {color: $ink-65; pointer-events: none;
      .icon {background-color: $ink-65; margin-left: 4px;}
    }
  }
  &.disabled {color: $ink-65; pointer-events: none;}
}

/* tooltips theme */
.tippy-box {
  &[data-theme~='custom'] {bottom: -10px;
    background-color: #000; color: #fff; border-radius: 3px; font-size: $tiny;
    .tippy-arrow {color: #000;
      &::before {transform: scale(0.7);}
    }
  }
  &[data-theme='light'] {
    .plantooltip {
      &-title {display: flex; justify-content: space-between; align-items: flex-end;}
      &-id {font-weight: 500; font-size: 1rem;}
      &-item {margin-bottom: 8px;
        &-mask {color: $ink-50;}
      }
      &-description {margin-bottom: 8px; padding-bottom: 8px; border-bottom: 1px solid $ink-80;}
    }
    .tippy-content {background-color: $paper-100; border-radius: 3px; color: $ink; border: 1px solid $ink-65;
      background-color: $paper-100; box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
      padding: 12px; font-size: $small; line-height: 18px; min-width: 186px; box-sizing: border-box;
    }
  }
  &[data-theme~='validation-error'] {
    background-color: $destructive; color: #fff; border-radius: 3px; font-size: $tiny;
    .tippy-arrow {color: $destructive;
      &::before {transform: scale(0.7);}
    }
  }
  .tippy-content {white-space: pre-line;}
}

/* handsontable context menu */
.handsontable {
  &.htDropdownMenu table.htCore {width: 300px;}
  &.htContextMenu {
    .wtHolder, .wtHider {max-width: 160px;}
    .wtSpreader {width: auto;}
    .htDisabled {color: $ink-80;
      &:hover {color: $ink-80;}
      &.current {background-color: transparent;}
      .context-menu-danger {color: $ink-80; cursor: default;}
    }
    table {width: auto; border-radius: 3px; overflow: hidden; box-shadow: 0 0 2px $ink-80; background: #fff;
      padding: 0 2px 4px; border-width: 1px !important; border-color: $ink-80 !important;
    }
    tbody {
      td {padding: 4px 0; margin: 0 2px; line-height: 21px; border-radius: 2px;
        &:hover {background-color: $paper-95;}
      }
      .htItemWrapper {margin-left: 8px;}
      .context-menu-section {padding: 0 8px; color: $ink-65; font-size: $small; border-bottom: 1px solid $ink-80;}
      .context-menu-danger {color: $destructive; margin: 0 6px 0 8px; cursor: pointer;}
    }
  }
}

/* page mode toggle */
.pagemodetoggle {display: inline-flex; align-items: center;
  &-message {margin-right: 15px;
    &-editor span {color: $ink-25; font-weight: bold;}
    .icon-mask {background-color: $prominent; margin-right: 8px;}
  }
}

/* breadcrumb */
.breadcrumb {list-style: none; padding-inline-start: 0; margin: 0 0 0.85rem;
  &-item {display: inline; font-size: $small; color: $ink-50;
    &:not(:last-child)::after {content: '/'; padding: 6px;}
    &:last-child {pointer-events: none;}
    a {color: inherit;
      &:hover {color: $ink-25;}
    }
  }
}

/* showmore */
.showmore {display: flex; justify-content: center; align-items: center;
  width: fit-content; padding: 5px; margin: 5px 0 -5px -5px;
  &.hidden {display: none;}
  .icon {transform: scale(1.4);
    &-mask {background: #000;}
  }
}

/* cdk-tree */
.cdk-tree {
  .toggle-button {background: none;
    .icon {transition: all 0.3s;}
    &-opened {
      .icon {transform: rotate(90deg);}
    }
  }
}
.tree {
  &-title {display: flex; align-items: center; font-size: $large; font-family: $font;
    cursor: pointer; color: $ink; font-weight: bold; padding: 0; width: 100%;
  }
  &-statistic {margin-left: 35px; color: $ink-50; font-size: $small; margin-bottom: 23px;}
  &-description {font-size: $small;
    &-item {color: $ink-50; display: inline-block; font-weight: 400;
      &:not(:last-child) {margin-right: 24px;}
    }
  }
  &-node {min-height: 42px; display: flex; align-items: center; flex: 1;
    word-wrap: break-word;
    &-toggle {color: inherit; flex: 1 1 auto;}
    &-title {color: $ink-50; padding: 10px 0 10px 5px;}
    .statuscounter-icon {min-width: 20px; line-height: 20px;}
  }
  &-outline {border-top: 2px solid $ink-80;}
  &-outline-bottom {border-bottom: 2px solid $ink-80;}
  &-info {padding: 20px 0;
    &-item {margin-bottom: 5px;}
    &-files {font-size: $small; color: $ink-50; margin-bottom: 9px;}
    &-details {display: flex;}
  }
  &-files {padding: 10px 0;
    .icon-arrow {
      &-down {background: $paper-100;}
      &-up {background: $primary;}
    }
    .button {margin-right: 16px;}
    .actions {display: flex; align-items: center; margin-bottom: 26px;}
  }
}

/* status-counter */
.statuscounter {margin-bottom: 27px; display: flex; justify-content: space-between;
  &-icon {border-radius: 4px; min-width: 24px; text-align: center; padding: 0 3px;
    line-height: 24px; color: #fff; font-size: $small; margin-right: 8px;
    display: inline-block; box-sizing: border-box;
    &-in-progress {background-color: $brand-yellow;}
    &-declined {background-color: $brand-orange-light;}
    &-under-inspection {background-color: $brand-orange;}
    &-contested {background-color: $brand-blue;}
    &-closed {background-color: $brand-green;}
    &-removed {background-color: $ink-80;}
    &-requesting-approval {background-color: $brand-purple;}
    &-requesting-approval-rejected {background-color: $brand-red;}
    &-accepted {background-color: $brand-green;}
  }
}

/* handover file */
.handover-file {padding: 8px 16px; border: 1px solid $ink-80;
  border-radius: 3px; margin-bottom: 8px; position: relative;
  .link {position: absolute; top: 0; bottom: 0; right: 0; left: 0; border: none;}
  &.active:hover {background-color: $paper-95; cursor: pointer;}
  .filewrapper, .comment {display: flex; margin-bottom: 8px; font-size: 1rem;}
  .comment {padding-top: 8px;}
  .metadata {margin-bottom: 8px; font-size: $small;
    &-uid {color: $ink-65;}
    &-item:not(:last-child) {margin-bottom: 4px;}
  }
  .file {flex: 1 1 auto; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;
    &-size {color: $ink-65;}
    &-name {margin-right: 16px;}
  }
  .signature {color: $ink-65; font-size: $small;
    &:not(:last-child) {margin-bottom: 8px;}
  }
  .actions {margin: 0; white-space: nowrap;
    &-item {position: relative;}
    &-item:not(:last-child) {margin-right: 16px;}
  }
  .image {margin-bottom: 6px;
    .icon-mask {background: $primary;}
  }
}

.assethandoverfilters {margin-bottom: 40px; display: grid;
  grid-template-rows: auto auto; grid-template-columns: 1fr 1fr 1fr;
  .form-group {width: 100%;}
  &-panel {display: grid; grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(4, 1fr); grid-row-gap: 22px; grid-column-gap: 12px;
    padding: 22px 24px 32px; margin-bottom: 28px; background-color: $paper-95;
    border-radius: 3px;
  }
}
/* history */
.history {position: relative;
  .status {color: $ink-50;}
  .status, &-signature {margin-bottom: 6px;}
  &-signature {font-size: $small;}
  .custom-scrollbar {max-height: 461px; overflow: hidden; overflow-y: auto;
    margin-right: -28px;
    &-content {margin-right: 28px;}
  }
}

/* switch */
.switch {background: $paper-95; border-radius: 3px; line-height: 36px;
  &-button {padding: 9px; line-height: 0; border-radius: 3px; border: 1px solid $paper-95; box-sizing: border-box;
    &.active, &:hover {background: $paper-100; border-color: $ink-80;}
  }
}

.contextmenu {display: inline-flex; flex-direction: column; min-width: 144px; background-color: $paper-100;
  padding: 2px; border-radius: 3px; box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
  &-item {font-weight: 400; padding: 8px 12px; border-radius: 3px; background-color: inherit; border: none;
    text-align: left; user-select: none;
    &:focus {background-color: inherit;}
    &:hover {background-color: $paper-98;}
    .icon {margin-right: 8px;}
  }
}

.tox {
  .tox-dialog {padding: 26px 32px 32px;}
  .tox-label {font-family: $font; margin-bottom: 8px;}
  .tox-dialog {
    &__footer {
      .tox-button {padding: 8px 14px; border-radius: 3px;font-weight: 400; font-family: $font; border: 1px solid $ink-80;
        &:not(.tox-button--secondary) {background-color: $primary; border-color: #325ba3;}
      }
    }
    &__header {margin-bottom: 12px;
      .tox-button--naked {color: $ink-65;
        &:hover:not(:disabled) {background-color: transparent;}
        &.tox-button--icon:hover:not(:disabled) {color: $ink-65;}
      }
      .tox-dialog__title {font-size: $middle; line-height: 150%; font-weight: 700; font-family: $font;}
    }
  }
  .tox-form {
    .tox-form__group {margin-bottom: 12px;
      .tox-textfield {border-radius: 3px;
        &:focus {box-shadow: 0 0 2px $primary; border-color: $primary;}
      }
    }
  }
  &.tox-tinymce {border: 1px solid $ink-80; border-radius: 3px;
    &--disabled {
      .tox-editor-container {
        .tox-editor-header,.tox-statusbar, .tox-toolbar__primary {background-color: $paper-95;}
      }
    }
  }
}